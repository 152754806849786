import dayjs from "dayjs";
import { useMemo } from "react";

const CurrentYear = () => {
  const year = useMemo(() => {
    return dayjs().format("YYYY");
  }, []);

  return <span>{year}</span>;
};

export default CurrentYear;
